
import { defineComponent, ref } from 'vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import { getPayUrl } from '../../api/statistics';

export default defineComponent({
  name: 'Recharge',
  components: { PageHeader },
  setup() {
    const payList = ref([
      {
        id: 49,
        title: '3000 条',
        price: 1000,
        desc: '适用于个人学习、工作室、创业阶段',
      },
      { id: 50, title: '7500 条', price: 2000, desc: '适用于小微型跨境电商企业', loading: false },
      {
        id: 51,
        title: '15000 条',
        price: 3500,
        desc: '适用于中小型跨境电商企业',
      },
      {
        id: 52,
        title: '33000 条',
        price: 7000,
        desc: '适用于大中型跨境电商企业',
      },
    ]);
    const url = ref('');
    const loading = ref(false);
    const getJumpUrl = async id => {
      const { data } = await getPayUrl({ proudctsid: id }, loading);
      if (!data) return;
      url.value = data.url;
    };

    const visible = ref(false);
    const modalKey = ref(0);
    const openModal = id => {
      modalKey.value = Date.now();
      visible.value = true;
      getJumpUrl(id);
    };

    return {
      payList,
      visible,
      openModal,
      modalKey,
      loading,
      url,
    };
  },
});
